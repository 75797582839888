import { AnalyticInstance } from './Analytics';

interface UseAnalytics {
  homePageViewed: () => void;
  startForFreeClicked: () => void;
  startForFreeHomeHeroClicked: () => void;
  startForFreeHeaderClicked: () => void;
  requestDemoClicked: () => void;
  chromeExtensionClicked: () => void;
  pricingPageMonthlyClicked: () => void;
  pricingPageAnnualClicked: () => void;
  pricingPageGetStartedClicked: () => void;
  pricingPageTalkToSalesClicked: () => void;
  bookDemoClicked: () => void;
  startForFreeBottomClicked: () => void;
}

export const useAnalytics = (): UseAnalytics => {
  return AnalyticInstance;
};
