import React, { useEffect, useRef } from 'react';

import styles from './styles.module.scss';
import { Box } from '@mui/material';
import type { StaticImageData } from 'next/image';

type Props = {
  src: string;
  poster: string | StaticImageData;
};

export const VideoAnimation: React.FC<Props> = ({ src, poster }) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = ref.current;
    if (!video) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Set the video source only when it comes into view
            if (!video.src) {
              video.src = src;
            }
            void video.play();
          } else {
            video.pause();
            video.currentTime = 0;
          }
        });
      },
      { threshold: 0.5 },
    );

    observer.observe(video);

    return (): void => {
      observer.unobserve(video);
    };
  }, [src]);

  return (
    <Box className={styles.wrapper}>
      <video
        style={{ width: '100%' }}
        ref={ref}
        className={styles.video}
        muted
        poster={typeof poster === 'object' ? poster.src : poster}
        playsInline
      />
    </Box>
  );
};
