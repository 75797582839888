import { useAuth } from '@/features/auth';
import { EXTERNAL_ROUTES } from '@/libs/routing';
import { Button } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useAnalytics } from '@/libs/analytics';
import clsx from 'clsx';

export const AuthButtons: FC = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { startForFreeClicked, startForFreeHeaderClicked } = useAnalytics();

  if (auth.isAuthenticated) {
    return (
      <Button
        color="secondary"
        variant="contained"
        className={styles.appBarButton}
        href={EXTERNAL_ROUTES.DASHBOARD_SIGN_IN}
      >
        {t('header.enterAccount')}
      </Button>
    );
  }

  return (
    <>
      <Button
        color="secondary"
        className={clsx(styles.appBarButton, styles.loginButton)}
        href={EXTERNAL_ROUTES.DASHBOARD_SIGN_IN}
      >
        {t('header.login')}
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          startForFreeClicked();
          startForFreeHeaderClicked();
        }}
        variant="contained"
        className={styles.appBarButton}
        href={EXTERNAL_ROUTES.DASHBOARD_SIGN_UP}
      >
        {t('header.startFree')}
      </Button>
    </>
  );
};
